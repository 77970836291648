(function(exports, constants, moment) {
    'use strict';

    function nextBusinessDay(date) {
        var loop = 1;
        var limit = 7;
        while(loop < limit) {
            date.add(1, 'd');
            if(isBusinessDay(date)) {
                break;
            }
            loop++;
        }
        return date;
    }

    function isBusinessDay(date) {
        if(date.day() === 0 || date.day() === 6) return false;
        return true;
    }

    function getCurrentMonth(start, end, isFileMaintenance) {
        var today = moment.utc(start); //moment.utc();
        var startOfMonth = moment(today).startOf('month');
        var firstBusniessDay = isBusinessDay(startOfMonth) ? startOfMonth : nextBusinessDay(startOfMonth);

        var diff = today.diff(firstBusniessDay, 'days'); // Get difference between today and first business day

        var currentMonthStartDate;

        if(diff >= 0 && diff < 1 || (diff >= 1 && diff < 2 && !isFileMaintenance)) //is first working day
            currentMonthStartDate = moment.utc().subtract(1, 'month').startOf('month');
        else if((diff >= 1 && diff < 2 && isFileMaintenance) || diff >= 2) //is second working day and isFileMaintenance or is third or greater working day
            currentMonthStartDate = today.startOf('month');

        return {
            startDate: currentMonthStartDate,
            endDate: moment(currentMonthStartDate).endOf('month')
        };
    }

    exports.calculatePeriod = function (currentBegDate, endDate, periodIndicator, isFileMaintenance) {
        
        var startDate = moment(endDate);
        switch(periodIndicator) {
            case (constants.periodIndicators.prior15Days.key):
                return {
                    startDate: startDate.subtract(15, 'days'),
                    endDate: endDate
                };

            case (constants.analysisPeriodIndicators.prior30Days.key):
            case (constants.periodIndicators.prior30Days.key):
            case (constants.paygoPeriodIndicators.priorMonth.key):
                return {
                    startDate: startDate.subtract(30, 'days'),
                    endDate: endDate
                };

            case (constants.analysisPeriodIndicators.currentMonth.key):
            case (constants.periodIndicators.currentMonth.key):
                return getCurrentMonth(currentBegDate, endDate, isFileMaintenance);

            case (constants.analysisPeriodIndicators.prior7Days.key):
            case (constants.servicePeriodIndicators.prior7Days.key):
                return {
                    startDate: startDate.subtract(7, 'days'),
                    endDate: endDate
                };

            case (constants.servicePeriodIndicators.prior14Days.key):
                return {
                    startDate: startDate.subtract(14, 'days'),
                    endDate: endDate
                };

            case (constants.paygoPeriodIndicators.priorMonth.key):
                return {
                    startDate: startDate.subtract(1, 'month'),
                    endDate: endDate
                };

            case (constants.analysisPeriodIndicators.priorMonth.key):
                var dates = getCurrentMonth(currentBegDate, endDate, isFileMaintenance);
                return {
                    startDate: dates.startDate.subtract(1, 'month'),
                    endDate: dates.endDate.subtract(1, 'month')
                };

            case (constants.paygoPeriodIndicators.prior6Months.key):
                return {
                    startDate: startDate.subtract(6, 'month'),
                    endDate: endDate
                };

            case (constants.paygoPeriodIndicators.prior12Months.key):
                return {
                    startDate: startDate.subtract(12, 'month'),
                    endDate: endDate
                };

            case (constants.periodIndicators.yearToDate.key):
            case (constants.invoicePeriodIndicators.yearToDate.key):
            case (constants.servicePeriodIndicators.yearToDate.key):
            case (constants.marginPeriodIndicators.yearToDate.key):
            case (constants.acquisitionIndicators.yearToDate.key):
            case (constants.terminationIndicators.yearToDate.key):
            case (constants.analysisPeriodIndicators.yearToDate.key):
                return {
                    startDate: moment().startOf('year'),
                    endDate: endDate
                };
            case (constants.marginPeriodIndicators.fiscalYearToDate.key):
                return {
                    startDate: currentBegDate ? moment(currentBegDate) : startDate,
                    endDate: moment()
                };

            /*Forecasting Periods */
            case (constants.forecastingPeriodIndicators.thisWeek.key):
                return {
                    startDate: moment.utc().startOf('week'),
                    endDate: moment.utc().endOf('week')
                };
            case (constants.forecastingPeriodIndicators.nextWeek.key):
                return {
                    startDate: moment.utc().add(1, 'w').startOf('week'),
                    endDate: moment.utc().add(1, 'w').endOf('week')
                };
            case (constants.forecastingPeriodIndicators.thisMonth.key):
                return {
                    startDate: moment.utc().startOf('month').startOf('week'),
                    endDate: moment.utc().endOf('month').endOf('week')
                };
            case (constants.forecastingPeriodIndicators.nextMonth.key):
                return {
                    startDate: moment.utc().add(1, 'month').startOf('month').startOf('week'),
                    endDate: moment.utc().add(1, 'month').endOf('month').endOf('week')
                };
            case (constants.forecastingPeriodIndicators.nextSixMonths.key):
                return {
                    startDate: moment.utc().add(1, 'month').startOf('month'),
                    endDate: moment.utc().add(6, 'month').endOf('month')
                };

            /* Number of Years */    
            case (constants.yearsPeriodIndicator.oneYear.key):
                return {
                    startDate: moment.utc().add(-1, 'year').startOf('month'),
                    endDate: moment.utc().endOf('month')
                };
            case (constants.yearsPeriodIndicator.twoYears.key):
                return {
                    startDate: moment.utc().add(-2, 'year').startOf('month'),
                    endDate: moment.utc().endOf('month')
                };
            case (constants.yearsPeriodIndicator.threeYears.key):
                return {
                    startDate: moment.utc().add(-3, 'year').startOf('month'),
                    endDate: moment.utc().endOf('month')
                };

            default:
                return {
                    startDate: currentBegDate ? moment(currentBegDate) : startDate,
                    endDate: endDate
                };
        }
    };

    exports.calculateDateOfWeek = function(year, weekOfYear) {
        //Get the first day of the week (monday) after adding (weekOfYear-1) weeks to the first day of [year]
        return moment([year]).add(weekOfYear - 1, 'w').startOf('week');
    };

    exports.getWorkingDaysBetween = function(start, end) {
        var numberOfDays = 0;

        var startDate = moment(start);
        var endDate = moment(end);
        var days = endDate.diff(startDate, 'days');
        var date = startDate.subtract(1, 'day');

        while(days >= 0) {
            date = date.add(1, 'days');
            days--;

            if(date.isoWeekday() !== 6 && date.isoWeekday() !== 7)
                numberOfDays++;
        }

        return numberOfDays;
    };

    exports.getFiscalYear = function(asOf, startMonth) {
        if(!moment.isMoment(asOf))
            asOf = moment(asOf);

        if(startMonth != 0 && asOf.month() >= 0 && (asOf.month() >= startMonth))
            return asOf.year() + 1;
        else
            return asOf.year();
    };

    exports.getCalendarYear = function(fiscalYear, startMonth, month) {
        if(startMonth == 0 || month < startMonth) return fiscalYear;
        return fiscalYear - 1;
    };

    exports.subtractWorkingDays = function(workingDayCount, startDt) {
        var numberOfDays = 0;

        var startDate = startDt ? moment(startDt) : moment();

        while(numberOfDays < workingDayCount) {
            var date = startDate.subtract(1, 'day');

            if(date.isoWeekday() !== 6 && date.isoWeekday() !== 7)
                numberOfDays++;
        }

        return startDate;
    };
})(
    typeof exports === 'undefined' ? this.dateCalculator = {} : exports,
    typeof exports === 'undefined' ? this.constants : require('./constants'),
    typeof exports === 'undefined' ? this.moment : require('moment')
);